import React, { useState } from 'react';
import * as tf from '@tensorflow/tfjs';

function ImageHandler() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [prediction, setPrediction] = useState(null);
    const [error, setError] = useState(null);
    const [file,setFile] = useState(null);

    const handleFileChange = (event) => {
        setFile(URL.createObjectURL(event.target.files[0]));
        setSelectedFile(event.target.files[0]);
        setPrediction(null);
        setError(null);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const response = await fetch('https://c49e-2409-40d0-1186-6a9d-a066-c343-1ba-e227.ngrok-free.app/predict', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setPrediction(data.predicted_class);
        } catch (err) {
            setError(err.message || 'Error predicting');
        }
    };

    return (
        <div>
            <h1 className='app-header'>PNUEMONIA DETECTION</h1>
            <form onSubmit={handleSubmit}>
                <input type="file" onChange={handleFileChange} accept="image/*" className='uploader'/>
                <button type="submit" className='btn-submit'>Predict</button>
            </form>
            <img src={file} className='img-uploaded'/>
            {prediction && <h2 className='predictions'>Prediction: {prediction}</h2>}
            {error && <h2 style={{ color: 'red' }}>Error: {error}</h2>}
            <p>Made with love 💌 by <a href="https://adarrrrsh.vercel.app/">Adarsh Pratap Singh</a></p>
        </div>

    );
}


export default ImageHandler;
