import logo from './logo.svg';
import './App.css';
import ImageHandler from './components/ImageHandler';

function App() {

  // const tf = require("@tensorflow/tfjs-node");
  return (
    <div className="App">
      <ImageHandler/>
    </div>
  );
}

export default App;
